import * as Stores from "./Stores"
import Store from "./Store"
import { WalleSearch } from "./Search"
import { MultiVitaminsPrompt, ClothingPrompt } from './Prompts'
import { OpenAiCompletion } from "./Completions"
import { SearchTool, CheckoutTool } from "./Tools"

// const Configuration = {}

// const { search } = process.env.NODE_ENV === 'development' ? MockedSearch() : MultiOnSearch()

// Configuration.store = process.env.NODE_ENV === 'development' ? Store([HockeySauceKit()], search) : Store([NatureMade()], search)

// Configuration.sideBarText = process.env.NODE_ENV === 'development' ? 'The Original Sauce Kit' : 'Buy Supplements'

// Configuration.startingOptions = process.env.NODE_ENV === 'development' ? ['Kits', 'Boards', 'Gear'] : ['Multivitamins']

// const systemMessages = process.env.NODE_ENV === 'development' ? [{ role: 'system', content: SunglassesPrompt }] : [{ role: 'system', content: MultiVitaminsPrompt }]
// const tools = [SearchTool, WalleOnboardingTool, CheckoutTool]

// Configuration.completions = OpenAiCompletion(tools, systemMessages)

// export default Configuration

const Configuration = {}

// const { search } = MockedSearch()
const { search } = WalleSearch()

const tools = [SearchTool, CheckoutTool]

let systemMessages
switch(process.env.REACT_APP_CONFIGURATION) {
    case 'supplements':
        Configuration.sideBarText = 'Buy Supplements'
        Configuration.store = Store([Stores.NatureMade(), Stores.MegaFood()], search)
        Configuration.startingOptions = ['MultiVitamins', 'Vitamin D', 'Fish Oil', 'Magnesium']
        systemMessages = [{ role: 'system', content: MultiVitaminsPrompt }]
        break
    case 'clothing':
        Configuration.sideBarText = 'Buy Clothing'
        Configuration.store = Store([Stores.HM(), Stores.JCrew()], search)
        Configuration.startingOptions = ['Shirt', 'Dress', 'Shoes', 'Jacket']
        systemMessages = [{ role: 'system', content: ClothingPrompt }]
        break
    default:
        throw new Error('invalid config')
}

Configuration.completions = OpenAiCompletion(tools, systemMessages)

export default Configuration