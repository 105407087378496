import { useState, createContext, useContext } from 'react'

// Create a context
const CartContext = createContext();

// Provide context to your app
export const CartProvider = ({ children }) => {
    const [cart, updateCart] = useState([]);
  
    const addToCart = (item, storeName) => {
        updateCart([{ item: item, storeName: storeName }])
    } 

    return (
    <CartContext.Provider value={{ cart, addToCart }}>
        {children}
    </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);