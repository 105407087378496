import { useState, createContext, useContext } from 'react'

// Create a context
const SlidingPaneContext = createContext();

// Provide context to your app
export const SlidingPaneProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null)
  const [data, setData] = useState(null)
  const [content, updateContent] = useState(null) 

  const showResults = (type, data) => {
    setType(type)
    setData(data)
    setIsOpen(true)
  }
  
  const clickableLink = (content, text) => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href='#' onClick={() => showContent(content)}>{text}</a>
    )
  }

  const showContent = (content) => {
    updateContent(content)
    setIsOpen(true)
  }

  // Function to close the sliding pane
  const closePane = () => setIsOpen(false);

  return (
    <SlidingPaneContext.Provider value={{ isOpen, showResults, closePane, clickableLink, type, data, showContent, content }}>
      {children}
    </SlidingPaneContext.Provider>
  );
};

export const useSlidingPane = () => useContext(SlidingPaneContext);