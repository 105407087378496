const UserMessage = ({ message }) => {
    const style = {
      backgroundColor: '#007AFF', // Dark blue background for user
      padding: '12px 16px',
      borderRadius: '15px',
      marginBottom: '10px',
      maxWidth: '60%', // Restricts the message width
      textAlign: 'right',
      alignSelf: 'flex-end', // Aligns the message to the right
      fontSize: '16px',
      color: '#fff', // White text for the user
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adds a slight shadow for 3D effect
      wordBreak: 'break-word',
    };
  
    return <div style={style}>{message}</div>;
};

export default UserMessage