const Store = (stores, search) => {
    
    const uniqueness_check = {}
    for (const store of stores) {
        if (uniqueness_check[store.name]) {
            throw new Error('store names cannot be the same')
        }

        uniqueness_check[store.name] = true
    }

    const getStore = (name) => {
        return stores.find((store) => store.name === name)
    }

    const estimateTotal = (cart) => {
        let estimatedTotal = 0
        // calculate each item with tax
        for (const obj of cart) {
            const { item: { priceCents }, storeName } = obj
            const { estimatedTaxPercentage } = getStore(storeName)
            console.log(estimatedTaxPercentage)
            estimatedTotal += priceCents * (1+ estimatedTaxPercentage/100.0)
        }

        // calculate shipping cost
        const shippingStore = {}
        for (const obj of cart) {
            const { storeName } = obj
            if (!shippingStore[storeName]) {
                const { estimatedShippingCents } = getStore(storeName)
                estimatedTotal += estimatedShippingCents
                shippingStore[storeName] = true
            }
        }

        return estimatedTotal

    }

    const searchStores = async (searchTerm) => {
        const results = [];
      
        const storePromises = stores.map(async (store) => {
          const { filterResults, searchUrl, formatResults, name: storeName, logo: storeLogo } = store;
          const url = searchUrl(searchTerm);
      
          let data = await search(url);
      
          console.log(data);
          // General filter
          data = data.filter(result => result.name && result.imageSrc && result.priceCents && result.productLink);
          // Store-level filter
          data = filterResults(data);
          // Store-level formatting
          formatResults(data);
      
          return { items: data, storeName, storeLogo };
        });
      
        const storeResults = await Promise.all(storePromises);
        results.push(...storeResults);
      
        return results;
      };

    return { searchStores, estimateTotal }
}

export default Store