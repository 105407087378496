import { useSlidingPane } from "./SlidingPaneProvider";

const SlidingPane = () => {
    const { closePane, content } = useSlidingPane();
  
    const slidingPaneStyle = {
      position: 'fixed', // Relative to Rest component
      top: '0',
      left: '0', // Slide in from the left
      width: '100%', // Full width of the Rest container
      height: '100%', // Full height of the Rest container
      backgroundColor: '#f0f0f0',
      transition: 'left 0.3s ease', // Smooth slide transition
      boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)',
      zIndex: 100, // Higher than other elements in Rest
      overflow: 'hidden', // Prevent scrolling of outer container
    };
  
    const headerStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px',
      borderBottom: '1px solid #ddd',
    };
  
    const closeButtonStyle = {
      background: 'none',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
      fontWeight: 'bold',
      color: '#333',
    };
  
    const contentStyle = {
      padding: '20px',
      overflowY: 'auto', // Enable vertical scrolling within the content if needed
      height: 'calc(100% - 60px)', // Ensure it covers the entire pane except the header
    };
  
    return (
      <div style={slidingPaneStyle}>
        {/* Header with the "X" button */}
        <div style={headerStyle}>
          <button style={closeButtonStyle} onClick={closePane}>
            &times;
          </button>
        </div>
  
        {/* Content area below the header */}
        <div style={contentStyle}>
          {content}
        </div>
      </div>
    );
  };

  export default SlidingPane