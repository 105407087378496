import OpenAI from 'openai';

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })

export const OpenAiCompletion = (tools, initMessages) => {
    
    const complete = async (messages) => {
        let response

        try {
            response =  await openai.chat.completions.create({
                model: 'gpt-4o',
                messages: [...initMessages, ...messages],
                // messages: [{ role: 'system', content: DEFAULT_SYSTEM_PROMPT}, ...messages],
                tools: tools
            })
        } catch (error) {
            console.log(error)
        }
    
        return response.choices[0].message
    }

    return { complete }
}