export const SearchTool = {
    "type": "function",
    "function": {
        "name": "search",
        "description": "Search online store for what the customer is looking for",
        "parameters": {
            "type": "object",
            "properties": {
                "query": {
                    "type": "string",
                    "description": "The search term"
                }
            },
            "required": ["query"],
            "additionalProperties": false
        }
    }
}

export const CheckoutTool = {
    "type": "function",
    "function": {
        "name": "checkout",
        "description": "Show the user a checkout page so they can finalize the purchase",
        "parameters": {
            "type": "object",
            "properties": {},
            "required": [],
            "additionalProperties": false
        }
    }
}

export const WalleOnboardingTool = {
    "type": "function",
    "function": {
        "name": "walle_onboarding",
        "description": "Collect a users cc information to shop on their behalf",
        "parameters": {}
    }
}