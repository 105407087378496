import { useState, createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid';

// Create a context
const StepsContext = createContext();

// Provide context to your app
export const StepsProvider = ({ children }) => {
    const [steps, updateSteps] = useState([])

    const addStep = (step) => {
        step.id = uuidv4()
        updateSteps((prevSteps) => [...prevSteps, step])
        
        return step
    }

    const updateStep = (id, updatedData) => {
        updateSteps((prevSteps) =>
          prevSteps.map(step => 
            step.id === id ? { ...step, ...updatedData } : step
          )
        );
      };

    const addUserChat = (message) => {
        const step = {
            type: 'user_message',
            component: 'UserMessage',
            state: {
                message: message
            },
            chatForLLM: { role: 'user', content: message }
        }

        addStep(step)
    }

    const addAssistantChat = (content) => {
        const step = {
            type: 'assistant_message',
            component: 'AssistantMessage',
            state: {
                content: content
            },
            chatForLLM: { role: 'assistant', content: content }
        }
        
        addStep(step)
    }

    const addSystemChat = (message) => {
        const step = {
            type: 'system_message',
            display: null,
            component: null,
            chatForLLM: { role: 'system', content: message }
        }
        
        addStep(step)
    }

    const lastStep = () => steps[steps.length - 1]
    
    const llmChat = () => steps.map(step => step.chatForLLM)

    const addToolCall = (llmResponse) => {
        const step = {
            type: 'tool_call',
            display: null,
            component: null,
            state: {},
            chatForLLM: llmResponse
        }

        addStep(step)
    }

    const newAddToolCall = (llmResponse) => {
        const step = {
            type: 'tool_call',
            component: 'ToolCall',
            state: {
                llmResponse: llmResponse,
                toolCalls: []
            },
            chatForLLM: llmResponse
        }

        addStep(step)
    }

    const addToolOutputs = (toolOutputs) => {
        toolOutputs.forEach(({ output, id }) => {
          const step = {
            type: 'tool_output',
            component: null,
            state: {},
            chatForLLM: {
              role: "tool",
              content: JSON.stringify(output),
              tool_call_id: id
            }
          };
      
          addStep(step);
        });
    };
      

  return (
    <StepsContext.Provider value={{ steps, addStep, addUserChat, addAssistantChat, addSystemChat, addToolCall, addToolOutputs, lastStep, llmChat, newAddToolCall, updateStep }}>
      {children}
    </StepsContext.Provider>
  );
};

export const useSteps = () => useContext(StepsContext);