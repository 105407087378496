import { useState, useEffect } from 'react'
import AssistantChat, { AssistantLoading } from './AssistantMessage'
import { useSlidingPane } from './SlidingPaneProvider'
import { useSteps } from './StepsProvider'
import { SearchResults, CheckoutPage } from './Products'
import Walle from './Walle'
import Configuration from './Configuration'

const ToolCall = ({ state, id }) => {
    const { llmResponse, toolCalls } = state
    
    const [toolIndex, updateToolIndex] = useState(toolCalls.length)

    const needsProcessing = llmResponse.tool_calls.length > toolIndex
    const finalTool = llmResponse.tool_calls.length - 1 === toolIndex
    
    const { clickableLink } = useSlidingPane()
    const { addToolOutputs, updateStep } = useSteps()

    const addToolCallToState = (toolCallId, toolName, output) =>{
        state.toolCalls = [
            ...toolCalls,
            {
                id: toolCallId,
                toolName: toolName,
                output: output
            }
        ]

        updateStep(id, { state: state })
    }

    const displayOutputs = () => {
        return toolCalls.map(({ toolName, output }) => {
            switch(toolName) {
                case 'search':
                    const searchResults = <SearchResults results={output} />
                    const countResults = output.reduce((acc, result) => acc + result.items.length, 0)
                    const linkToSearchResults = <>
                        <p>I found {countResults} results for you</p>
                        {clickableLink(searchResults, 'View Here')}
                    </>

                    return <AssistantChat content={linkToSearchResults} />
                case 'walle_onboarding':
                    const onboardingComponent = <Walle />
                    const linkToOnboarding = <>
                        <p>Please complete the Walle onboarding so I can shop for you</p>
                        {clickableLink(onboardingComponent, 'Sign Up Here')}
                    </>

                    return <AssistantChat content={linkToOnboarding} />
                
                case 'checkout':
                    const checkoutComponent = <CheckoutPage />
                    const linkToCheckoutPage = <>
                        <p>Please review your order</p>
                        {clickableLink(checkoutComponent, 'Click here')}
                    </>

                    return <AssistantChat content={linkToCheckoutPage} />
                default:
                    throw new Error(`Unhandled toolName: ${toolName}`);
            }
        })
    }

    const incrementToolIndex = () => updateToolIndex(toolIndex+1)

    // // updates user on what tool is being called
    // useEffect(() => {
    //     if (needsProcessing) {
    //         switch(toolName()) {
    //             case 'search':
    //                 addToDisplay(<p>Searching...</p>)
    //                 break
    //     }}
    // }, [toolIndex])

    // console.log(toolIndex)

    // calls tool
    useEffect(() => {
        const callTool = async() => {
            let output
            
            const toolName = llmResponse.tool_calls[toolIndex].function.name
            const args = JSON.parse(llmResponse.tool_calls[toolIndex].function.arguments)
            const toolCallId = llmResponse.tool_calls[toolIndex].id
            
            switch(toolName) {
                case 'search':
                    // output = 
                    output = []
                    const searchTerm = args.query
                    const store = Configuration.store
                    output = await store.searchStores(searchTerm)
                    // const { save } = InMemoryBackend()
                    // save('search_results', output)
                    addToolCallToState(toolCallId, toolName, output)
                    break
                case 'walle_onboarding':
                    output = 'Walle Onboarding screen has been shown to the user'
                    addToolCallToState(toolCallId, toolName, output)
                    break
                case 'checkout':
                    // TODO need to double check that walle user id has been provided
                    output = 'Checkout page has been shown to the user'
                    addToolCallToState(toolCallId, toolName, output)
                    break
                default:
                    throw new Error(`Unhandled toolName: ${toolName}`);
            }
        }

        const processTool = async () => {
            await callTool()

            if (finalTool) {
                addToolOutputs(state.toolCalls)
            }

            incrementToolIndex()
        }
        
        if (needsProcessing) {
            processTool()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolIndex])

    return (
        <>
            {displayOutputs()}
            {/* TODO make this a real thing */}
            { needsProcessing && <AssistantLoading text='Agent is working. This may take up to 30 seconds.'/>}
        </>
    )
}

export default ToolCall