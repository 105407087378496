import { MultiOnClient } from "multion";

const MULTI_KEY = process.env.REACT_APP_MULTI_KEY

export const MockedSearch = () => {
    const search = async (url) => {
      if (/madeindetroit/.test(url)) {
        return [
          {
            name: 'Sunglasses',
            priceCents: 500,
            description: 'The baddest sunglasses in the midwest',
            productLink: 'https://www.madeindetroit.com/products/detroit-sunglasses',
            imageSrc: 'https://www.madeindetroit.com/cdn/shop/files/DetroitSunglasses-front.jpg?v=1683820153&width=960'
          }
        ]
      } else if (/hockeysaucekit/.test(url)) {
        return [
          {
            name: 'Bauer x Original Hockey Sauce Full Kit *Best Seller*',
            priceCents: 17999,
            description: 'The Original Hockey Yard Game + Training & Trick Shot Kit',
            productLink: 'https://www.madeindetroit.com/products/detroit-sunglasses',
            imageSrc: 'https://www.hockeysaucekit.com/cdn/shop/files/PhotoRoom_20231214_102558.jpg?v=1702571236&width=720'
          }
        ]
      } else if (/miragepetproducts/.test(url)) {
        return [
          {
            name: 'Pet Dog & Cat Nylon Collar Plain',
            priceCents: 570,
            description: 'This fashionable, durable nylon ribbon dog collar or cat collar is sure to make your pet the talk of the neighborhood!',
            productLink: 'https://www.madeindetroit.com/products/detroit-sunglasses',
            imageSrc: 'https://miragepetproducts.com/cdn/shop/products/il_fullxfull.1106816735_6s8d.jpg?v=1727986307&width=1946'
          }
        ]
      }

      
        // return [
        //     {
        //       name: 'Max Q-10',
        //       description: 'Support cellular energy plus heart and brain health with this potent formula featuring a pure, trans-form variety of CoQ10 that is bioidentical to CoQ10 produced naturally in your body, along with antioxidant-rich BioPerine®^^ to boost CoQ10 absorption up to 30%.*',
        //       imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_6f20d30e-6d70-46d4-ac66-b4c6a736e3b3.webp?v=1724709979&width=606',
        //       priceCents: 2799,
        //       productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //     },
        //     {
        //       name: 'Multi 360',
        //       description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
        //       imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
        //       priceCents: 3999,
        //       productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //     },
        //     {
        //         name: 'Multi 360',
        //         description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
        //         imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
        //         priceCents: 3999,
        //         productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //       },
        //       {
        //         name: 'Multi 360',
        //         description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
        //         imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
        //         priceCents: 3999,
        //         productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //       },
        //       {
        //         name: 'Multi 360',
        //         description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
        //         imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
        //         priceCents: 3999,
        //         productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //       },
        //       {
        //         name: 'Multi 360',
        //         description: 'This advanced formula is a step up from our Multi360® Complete, offering 28 essential vitamins, minerals, herbs and nutrients with antioxidant power equal to 10 servings of fruits and vegetables for optimal wellness and healthy aging. *',
        //         imageSrc: 'https://www.stopagingnow.com/cdn/shop/files/1_7d6d5372-07d9-49f0-826d-363dec70def0.webp?v=1724709880&width=606',
        //         priceCents: 3999,
        //         productLink: 'https://www.stopagingnow.com/products/purzanthin-ultra-astaxanthin-12-mg-30-softgels-sa26141'
        //       }
        // ]
    }
    
   return { search }
}

export const MultiOnSearch = () => {
    const search = async (url) => {
        const multion = new MultiOnClient({ apiKey: MULTI_KEY})
        const retrieveResponse = await multion.retrieve({
            cmd: "Get all of the products on this page including the name, the description, the image source, the price in cents (1099 instead of 10.99), and the link to the product",
            url: url,
            fields: ["name", "description", "imageSrc", "priceCents", "productLink"],
            scrollToBottom: true,
            useProxy: true
        });
        
        return retrieveResponse.data;
    }
    
   return { search }
}

export const WalleSearch = () => {
  const search = async (url) => {
    const response = await fetch('https://sllpxnyprwgvfoe3hhgglhzove0qzfjb.lambda-url.us-east-2.on.aws/', {
      method: 'POST',  // Use POST to send data to the Lambda function
      headers: {
        'Content-Type': 'application/json',  // Inform the server that you're sending JSON data
      },
      body: JSON.stringify({ url }),  // Send the URL in the request body
    });

    const jsonResponse = await response.json();  // Await the parsed JSON response
    console.log(jsonResponse);
    return jsonResponse;
  };

  return { search };
};