import React, { useEffect, useState } from "react";

const WALLE_BASE = process.env.REACT_APP_WALLE_BASE || 'https://app.usewalle.com'

const Walle = ({ appId, onComplete, purchaseInfo, registrationPrefill = {}, onboarding = {}, scopes='user,address,card' }) => {
  const { purchaseDescription, expectedPurchaseAmountCents, cart } = purchaseInfo
  const { firstName = '', lastName = '', email = '' } = registrationPrefill;
  const { address = true } = onboarding

  if (!purchaseDescription) {
    throw new Error('purchaseInfo must have purchaseDescription value')
  }

  if (!expectedPurchaseAmountCents) {
    throw new Error('purchaseInfo must have expectedPurchaseAmountCents value')
  }

  if (!cart) {
    throw new Error('purchaseInfo must have cart value')
  }
  
  const [loggedIn, updatedLoggedIn] = useState(false)
  const [onboardingAddressComplete, updateOnboardingAddressComplete] = useState(!address)
  const [onboardingCardComplete, updateOnboardingCardComplete] = useState(false)
  const [userAuthToken, updateUserAuthToken] = useState(null)
  const [stateValue] = useState(() => Math.random().toString(36).slice(2, 17));
  const [sessionToken, updateSessionToken] = useState(null)

  const loggedInEvent = 'loggedIn'
  const onboardingAddressEvent = 'addressSaved'
  const onboardingCardEvent = 'cardSaved'
  const authorizationEvent = 'authorizationComplete'
  const sessionEvent = 'newSessionToken'

  useEffect(() => {
    // Function to handle messages from the iframe
    function handleWalleEvent(event) {
      // Check if the message is from the expected origin and the message type is correct
      switch(event.data.type) {
        case loggedInEvent:
          updatedLoggedIn(true)
          break
        case onboardingAddressEvent:
          updateOnboardingAddressComplete(true)
          break
        case onboardingCardEvent:
          updateOnboardingCardComplete(true)
          break
        case authorizationEvent:
          if (event.data.data.state !== stateValue) {
            throw new Error("invalid state")
          }
          updateUserAuthToken(event.data.data.userAuthToken)
          break
        case sessionEvent:
          updateSessionToken(event.data.data.token)
          break
        default:
          throw new Error("Invalid event")
      }
    }

    window.addEventListener('message', handleWalleEvent);

    return () => {
      window.removeEventListener('message', handleWalleEvent);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn && onboardingAddressComplete && onboardingCardComplete && userAuthToken && sessionToken) {
      onComplete(userAuthToken, sessionToken)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken])

  let style = {
    width: '500px',
    minHeight: '100vh',
    margin: '0 auto',
    border: 'none',
    background: 'inherit'
  };

  let link
  if (!loggedIn) {
    link = `${WALLE_BASE}/onboarding_register?first_name=${firstName}&last_name=${lastName}&email=${email}`;
    
    style = {
      width: '400px',
      height: '800px',
      margin: '0 auto',
      border: 'none',
      background: 'inherit'
    };

    return (
      <iframe title="Walle Login" src={link} style={style} />
    )
  } else if (!onboardingAddressComplete) {
    link = `${WALLE_BASE}/onboarding_address`

    style = {
      width: '400px',
      height: '800px',
      border:'none',
    }

    return (
      <iframe title="Walle Onboarding Address" src={link} style={style} />
    )
  } else if (!onboardingCardComplete) {
    link = `${WALLE_BASE}/onboarding_card`

    style = {
      width: '400px',
      height: '700px',
      border:'none',
    }

    return (
      <iframe title="Walle Onboarding Card" src={link} style={style} />
    )
  } else if (!userAuthToken) {
    link = `${WALLE_BASE}/authorize_app?app_id=${appId}&scopes=${scopes}&state=${stateValue}`;
    
    style = {
      width: '400px',
      height: '1000px',
      border:'none',
    }

    return (
      <iframe title="Walle Authorization" src={link} style={style} />
    )
  } else if (!sessionToken) {
    const purchaseDescriptionParam = encodeURIComponent(purchaseDescription)
    const cartParam = encodeURIComponent(JSON.stringify(cart))
    const link = `${WALLE_BASE}/start_purchase?app_id=${appId}&purchase_description=${purchaseDescriptionParam}&expected_purchase_amount_cents=${expectedPurchaseAmountCents}&cart=${cartParam}`;
    
    style = {
      width: '400px',
      height: '1000px',
      border:'none',
    }

    return (
      <iframe title="Walle Purchase" src={link} style={style} />
    )
  } else {
    return null
  }
};

export default Walle